<template>
  <div class="content">

    <div
      class="table-content"
      style="padding-top:20px;"
    >
      <a-button
        size='small'
        style="margin:5px;"
        @click="saveRole"
      >保存</a-button>
      <a-button
        size='small'
        style="margin:5px;"
        @click="delRole"
      >删除</a-button>

      <a-radio-group
        v-model="roleId"
        v-for="row in data"
        :key="row.id"
        size="large"
        button-style="outline"
        @change="onChange(row.id)"
      >
        <a-radio :value="row.id">
          {{row.roleName}}
        </a-radio>

      </a-radio-group>

      <a-input
        placeholder='请输入角色新名称'
        v-model="roleName"
        style="margin-top: 20px"
      ></a-input>

      <a-tree
        v-model="checkedKeys"
        checkable
        :expanded-keys="expandedKeys"
        :auto-expand-parent="autoExpandParent"
        :selected-keys="selectedKeys"
        :tree-data="treeData"
        :replaceFields="replaceFields"
        checkStrictly
        style="margin:20px"
        @expand="onExpand"
        @select="onSelect"
      />
    </div>

  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      columns: [],
      data: [],
      searchParams: {},
      rules: {},
      forms: {},
      visible: false,
      roleId: 1,
      loading: false,
      treeData: [],
      menuIds: [],
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      replaceFields: { children: "children", title: "menuName", key: "id" },
      roleName: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getData(this.searchParams);
  },
  methods: {
    ...mapActions(["updataMenu"]),
    async getData(params) {
      this.loading = true;
      const res = await this.axios("/platform/platformrole/list", {
        params: params,
      });
      this.loading = false;
      this.data = res.data;
      this.data.push({ id: -1, roleName: "新增" });
      this.roleId = this.data[0].id;
      const baseTree = await this.axios(
        "/platform/platformrole/getAllRoleList"
      );
      this.treeData = baseTree.data.menuListVos;
      this.onChange(this.roleId);
    },

    search() {
      this.getData(this.searchParams);
    },

    resetForms() {
      this.forms = {};
      this.$refs.ruleForm.resetFields();
    },

    onChange(e) {
      if (e == -1) {
        this.roleName = "";
        this.checkedKeys = [];
        return;
      }
      this.axios("/platform/platformrole/getById", { params: { id: e } }).then(
        (res) => {
          if (res.status == "200") {
            this.checkedKeys = res.data.menuIds.split(",").map(function (data) {
              return +data;
            });
            this.roleName = res.data.roleName;
          }
        }
      );
    },

    // tree
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys) {
      this.checkedKeys = checkedKeys;
    },
    onSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys;
    },
    saveRole() {
      let s = "";
      if (this.checkedKeys.checked != undefined) {
        s = this.checkedKeys.checked.toString();
      } else {
        s = this.checkedKeys.toString();
      }

      if (this.roleName == "") {
        this.$message.error("角色名称不能为空");
        return;
      }
      let post_url = "";
      let data = {};
      if (this.roleId != -1) {
        post_url = "/platform/platformrole/edit";
        data = { id: this.roleId, roleName: this.roleName, menuIds: s };
      } else {
        post_url = "/platform/platformrole/add";
        data = { roleName: this.roleName, menuIds: s };
      }

      this.axios.post(post_url, data).then((res) => {
        if (res.status == "200") {
          this.$message.success(res.message);
          this.getData(this.params);
        }
      });
      this.updataMenu();
    },
    delRole() {
      if (this.roleId < 0) {
        this.$message.error("未选中需要删除的角色");
        return;
      }
      this.axios("/platform/platformrole/delPlatformRoleById", {
        params: { id: this.roleId },
      }).then((res) => {
        if (res.status == "200") {
          this.$message.info(res.message);
        }
        this.getData(this.params);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-attr {
  display: flex;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
</style>