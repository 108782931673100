var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "table-content", staticStyle: { "padding-top": "20px" } },
      [
        _c(
          "a-button",
          {
            staticStyle: { margin: "5px" },
            attrs: { size: "small" },
            on: { click: _vm.saveRole }
          },
          [_vm._v("保存")]
        ),
        _c(
          "a-button",
          {
            staticStyle: { margin: "5px" },
            attrs: { size: "small" },
            on: { click: _vm.delRole }
          },
          [_vm._v("删除")]
        ),
        _vm._l(_vm.data, function(row) {
          return _c(
            "a-radio-group",
            {
              key: row.id,
              attrs: { size: "large", "button-style": "outline" },
              on: {
                change: function($event) {
                  return _vm.onChange(row.id)
                }
              },
              model: {
                value: _vm.roleId,
                callback: function($$v) {
                  _vm.roleId = $$v
                },
                expression: "roleId"
              }
            },
            [
              _c("a-radio", { attrs: { value: row.id } }, [
                _vm._v(" " + _vm._s(row.roleName) + " ")
              ])
            ],
            1
          )
        }),
        _c("a-input", {
          staticStyle: { "margin-top": "20px" },
          attrs: { placeholder: "请输入角色新名称" },
          model: {
            value: _vm.roleName,
            callback: function($$v) {
              _vm.roleName = $$v
            },
            expression: "roleName"
          }
        }),
        _c("a-tree", {
          staticStyle: { margin: "20px" },
          attrs: {
            checkable: "",
            "expanded-keys": _vm.expandedKeys,
            "auto-expand-parent": _vm.autoExpandParent,
            "selected-keys": _vm.selectedKeys,
            "tree-data": _vm.treeData,
            replaceFields: _vm.replaceFields,
            checkStrictly: ""
          },
          on: { expand: _vm.onExpand, select: _vm.onSelect },
          model: {
            value: _vm.checkedKeys,
            callback: function($$v) {
              _vm.checkedKeys = $$v
            },
            expression: "checkedKeys"
          }
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }